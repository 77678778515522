var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('Header'),_c('main',[_vm._m(0),_c('section',{staticClass:"work__ top_section",attrs:{"id":"work1"}},[_c('div',{staticClass:"container"},[_vm._m(1),_c('div',{staticClass:"kiswah_cards web"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{directives:[{name:"inview",rawName:"v-inview:class",value:(['animate__slideInLeft']),expression:"['animate__slideInLeft']",arg:"class"}],staticClass:"c_card__ animate__animated second_"},[_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"col-md-8"},[_c('div',{directives:[{name:"inview",rawName:"v-inview:class",value:(['animate__slideInUp']),expression:"['animate__slideInUp']",arg:"class"}],staticClass:"c_card__ animate__animated flex_style"},[_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{directives:[{name:"inview",rawName:"v-inview:class",value:(['animate__slideInRight']),expression:"['animate__slideInRight']",arg:"class"}],staticClass:"c_card__ animate__animated blue__"},[_vm._m(6),_vm._m(7)])])])])])]),_vm._m(8)])]),_c('section',{staticClass:"features",attrs:{"id":"features"}},[_c('div',{staticClass:"container",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"row"},[_vm._m(9),_c('div',{staticClass:"image col-lg-6",style:({ backgroundImage: `url(${require('@/assets/img/about-us.jpeg')})` }),attrs:{"data-aos":"fade-right"}})])])])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"work__ top_section",attrs:{"id":"work"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section_header"},[_c('h2',[_vm._v(" كيف نعمل ")])]),_c('div',{staticClass:"kiswah_cards web"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/Poster3.png")}})]),_c('div',{staticClass:"col-md-4"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/Poster2.png")}}),_c('img',{staticClass:"img-fluid mt-4",attrs:{"src":require("@/assets/img/Poster5.png")}})]),_c('div',{staticClass:"col-md-4"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/Poster1.png")}}),_c('img',{staticClass:"img-fluid mt-4",attrs:{"src":require("@/assets/img/Poster4.png")}})])])]),_c('div',{staticClass:"kiswah_cards mobile"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/Poster1.png")}}),_c('img',{staticClass:"img-fluid mt-4",attrs:{"src":require("@/assets/img/Poster2.png")}}),_c('img',{staticClass:"img-fluid mt-4",attrs:{"src":require("@/assets/img/Poster3.png")}}),_c('img',{staticClass:"img-fluid mt-4",attrs:{"src":require("@/assets/img/Poster4.png")}}),_c('img',{staticClass:"img-fluid mt-4",attrs:{"src":require("@/assets/img/Poster5.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_header"},[_c('h2',[_vm._v("كيفية عملنا مع الشركات")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_card_img"},[_c('img',{attrs:{"src":require("@/assets/img/1006.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_card_info"},[_c('h3',[_c('span',{staticClass:"num"},[_vm._v("2")]),_vm._v(" توقيع الاتفاقية")]),_c('p',[_vm._v("وضع بنود العقد المتفق عليه بين الجهتين")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_card_img"},[_c('img',{attrs:{"src":require("@/assets/img/1005.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_card_info"},[_c('h3',[_c('span',{staticClass:"num"},[_vm._v("1")]),_vm._v("تحديد الأدوار")]),_c('p',[_vm._v("التعريف بالشركة ودورها مع الجهات التي سنغطيها داخل المملكة")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_card_img"},[_c('img',{attrs:{"src":require("@/assets/img/1007.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_card_info"},[_c('h3',[_c('span',{staticClass:"num"},[_vm._v("3")]),_vm._v(" التجميع والتصنيف")]),_c('p',[_vm._v("يقوم فريق جرين كلوزيت بجمع الملابس الفائضة من العملاء وتصنيفها داخل مراكز الشركة")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kiswah_cards mobile"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"c_card__ animate__animated flex_style"},[_c('div',{staticClass:"c_card_img"},[_c('img',{attrs:{"src":require("@/assets/img/1005.png"),"alt":""}})]),_c('div',{staticClass:"c_card_info"},[_c('h3',[_c('span',{staticClass:"num"},[_vm._v("1")]),_vm._v("تحديد الأدوار")]),_c('p',[_vm._v("التعريف بالشركة ودورها مع الجهات التي سنغطيها داخل المملكة")])])])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"c_card__ animate__animated second_"},[_c('div',{staticClass:"c_card_img"},[_c('img',{attrs:{"src":require("@/assets/img/1006.png"),"alt":""}})]),_c('div',{staticClass:"c_card_info"},[_c('h3',[_c('span',{staticClass:"num"},[_vm._v("2")]),_vm._v(" توقيع الاتفاقية")]),_c('p',[_vm._v("وضع بنود العقد المتفق عليه بين الجهتين")])])])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"c_card__ animate__animated blue__"},[_c('div',{staticClass:"c_card_img"},[_c('img',{attrs:{"src":require("@/assets/img/1007.png"),"alt":""}})]),_c('div',{staticClass:"c_card_info"},[_c('h3',[_c('span',{staticClass:"num"},[_vm._v("3")]),_vm._v(" التجميع والتصنيف")]),_c('p',[_vm._v("يقوم فريق جرين كلوزيت بجمع الملابس الفائضة من العملاء وتصنيفها داخل مراكز الشركة")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 ar",attrs:{"data-aos":"fade-left","data-aos-delay":"100"}},[_c('div',{staticClass:"icon-box mt-5",attrs:{"data-aos":"zoom-in","data-aos-delay":"150"}},[_c('i',{staticClass:"bx bx-sun"}),_c('h4',[_vm._v("رؤيتنا")]),_c('p',[_vm._v("الريادة عالمياً بمعايير دولية في مجال إعادة تدوير الملابس المستعملة والوصول إلى مجتمع على مستوى عالي من الوعي والممارسات الإيجابية التي تكفل حماية البيئة من خطر النفايات النسيجية")])]),_c('div',{staticClass:"icon-box mt-5",attrs:{"data-aos":"zoom-in","data-aos-delay":"150"}},[_c('i',{staticClass:"bx bx-rocket"}),_c('h4',[_vm._v("مهمتنا ")]),_c('p',[_vm._v("تعزيز الاستدامة من خلال جمع الملابس المستعملة وإعادة استخدامها مرة أخرى اوإعادة تدويرها لإنتاج مواد تصنيعية خام من جديد لتقليل نفايات الملابس والحفاظ على الطبيعة وحماية البيئة")])]),_c('div',{staticClass:"icon-box mt-5",attrs:{"data-aos":"zoom-in","data-aos-delay":"150"}},[_c('i',{staticClass:"bx bx-target-lock"}),_c('h4',[_vm._v("هدفنا")]),_c('p',[_vm._v("نشر الوعي البيئي في المجتمع والمنظمات الربحية والغير ربحية للحفاظ على البيئة من خطر النفايات النسيجية")])])])
}]

export { render, staticRenderFns }